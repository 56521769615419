<template>
  <div>
    <b-modal id="modal-select" modal-class="modal-primary" title="รูปภาพทั้งหมด" hide-footer>
      <b-row cols="2" cols-sm="3" cols-md="4" no-gutters>
        <b-col
          v-for="image in wallpaperDatas.allImage"
          :key="image.id"
          @click="selectImage(image)"
          class="image-preview-item"
        >
          <img :src="image.file.previewUrl" alt="image-preview" class="image-preview" />
        </b-col>
      </b-row>
    </b-modal>
    <b-button variant="primary" block class="mb-3" @click="showModalSelect">
      เลือกรูปวอลเปเปอร์หลักเพื่อพรีวิว
    </b-button>

    <div v-if="wallpaperDatas.mainImage.file && active">
      <Editor
        :options="_optionsEditor"
        :imgUrl="wallpaperDatas.mainImage.file.previewUrl"
        :defaultValue="wallpaperDatas.mainImage.defaultValuePositionList"
        ref="refEditor"
      />
    </div>
  </div>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import Editor from './editor/Editor.vue'

export default {
  components: {
    BModal,
    Editor,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    active: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('wallpapers', ['wallpaperDatas']),
    _optionsEditor() {
      if (this.wallpaperDatas.mainImage.zodiac) {
        return [
          {
            key: 'text',
            hint: 'จุดวางตัวอักษร',
          },
          {
            key: 'zodiac',
            hint: 'จุดวางราศี',
          },
        ]
      }
      return [
        {
          key: 'text',
          hint: 'จุดวางตัวอักษร',
        },
      ]
    },
  },
  methods: {
    showModalSelect() {
      this.$bvModal.show('modal-select')
    },
    selectImage(image) {
      // console.log('select image', { image })
      this.wallpaperDatas.mainImage.file = image.file
      // this.wallpaperDatas.mainImage.id = image?.id || ''
      this.wallpaperDatas.mainImage.changeId = image?.id || ''
      this.$bvModal.hide('modal-select')
    },
  },
  mounted() {
    // console.log('editor parent mounted')
  },
}
</script>

<style lang="scss" scoped>
.image-preview-item {
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
}
.image-preview {
  width: 100%;
}
</style>
