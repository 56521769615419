<template>
  <div class="">
    <b-overlay :show="pageLoading">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <b-form-group label="ข้อความทดสอบ" label-for="a-code">
            <b-form-input id="a-code" type="text" placeholder="ระบุข้อความทดสอบ" v-model="text" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="สีตัวอักษรทดสอบ" label-for="b-code">
            <b-form-input id="b-code" type="color" v-model="fontColor" />
          </b-form-group>
        </b-col>

        <!-- select font TH -->
        <b-col sm="12" md="6">
          <b-form-group label="เลือกฟ้อน TH" label-for="fontFamilyTH">
            <v-select
              v-model="optionsFont.TH.name"
              :options="optionsFontNameTH"
              label="family"
              :style="{ 'font-family': cFontActiveTH }"
            >
              <template v-slot:option="option">
                <span :class="option.icon"></span>
                <div :style="{ 'font-family': option.family }">{{ option.family }}</div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="เลือกสไตล์ฟ้อน TH" label-for="fontStyleTH">
            <v-select
              v-model="optionsFont.TH.style"
              label="font"
              :options="optionsFont.TH.options"
              :getOptionLabel="getOptionLabelStyleFont"
              :disabled="optionsFont.TH.name === null || optionsFont.TH.options.length <= 0"
              :clearable="false"
            />
          </b-form-group>
        </b-col>

        <!-- select font EN -->
        <b-col sm="12" md="6">
          <b-form-group label="เลือกฟ้อน EN" label-for="fontFamilyEN">
            <v-select
              v-model="optionsFont.EN.name"
              :options="paginated"
              label="family"
              @open="onOpen"
              @close="onClose"
              @search="query => (search = query)"
              :style="{ 'font-family': cFontActiveEN }"
            >
              <template v-slot:option="option">
                <span :class="option.icon"></span>
                <div :style="{ 'font-family': option.family }">{{ option.family }}</div>
              </template>
              <template #list-footer>
                <li v-show="hasNextPage" ref="load" class="loader">
                  <div class="d-flex justify-content-center mb-1 loader">
                    <b-spinner type="grow" variant="primary" />
                  </div>
                </li>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="เลือกสไตล์ฟ้อน EN" label-for="fontStyleEN">
            <v-select
              v-model="optionsFont.EN.style"
              label="font"
              :options="optionsFont.EN.options"
              :getOptionLabel="getOptionLabelStyleFont"
              :disabled="optionsFont.EN.name === null || optionsFont.EN.options.length <= 0"
              :clearable="false"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12">
          <b-button @click="generateImagePreview" variant="primary" block class="custom-mt mb-md-0">
            ดูรูปพรีวิว
          </b-button>
        </b-col>
      </b-row>
      <div class="container-preview mt-1">
        <div v-if="!loading">
          <div v-if="urlPreview" class="ct-cursor-zoom">
            <img :src="urlPreview" alt="image-preview" class="image-preview" @click="gZoomImage" />
          </div>
          <div v-else class="container-loading-custom">
            <h4 class="mb-0 text-muted">
              โปรดระบุข้อความทดสอบเพื่อนสร้างรูปพรีวิวที่ด้านบน
            </h4>
          </div>
        </div>
        <div v-else class="container-loading-custom">
          <b-spinner variant="primary" type="grow" />
          <p class="mt-2 text-muted">กำลังสร้างรูปพรีวิว โปรดรอสักครู่</p>
        </div>
      </div>
      <!-- <pre>{{ JSON.stringify(optionsFont, null, 2) }}</pre> -->
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: { vSelect, ToastificationContent },
  props: {
    focus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      urlPreview: null,
      loading: false,
      text: '',
      fontColor: '#000000',
      optionsFont: {
        TH: { name: '', style: '', options: [] },
        EN: { name: '', style: '', options: [] },
      },
      optionsFontNameTH: [],
      optionsFontNameEN: [],
      localActiveFontTH: null,
      localActiveFontEN: null,
      observer: null,
      limit: 20,
      search: '',
      pageLoading: false,
    }
  },
  computed: {
    ...mapState('wallpapers', ['wallpaperDatas']),
    filtered() {
      return this.optionsFontNameEN.filter(en => en.family.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length
    },
    cFontActiveTH() {
      return this.optionsFont.TH.name?.family ?? null
    },
    cFontActiveEN() {
      return this.optionsFont.EN.name?.family ?? null
    },
  },
  watch: {
    'optionsFont.TH.name': function(newValue, oldValue) {
      if (!this.focus) return

      if (newValue === null) {
        this.optionsFont.TH.style = null
        this.optionsFont.TH.options = []
        return
      }

      this.fetchGoogleFont('TH')
    },
    'optionsFont.EN.name': function(newValue, oldValue) {
      // console.log(newValue)
      if (!this.focus) return

      if (newValue === null) {
        this.optionsFont.EN.style = null
        this.optionsFont.TH.options = []
        return
      }

      this.fetchGoogleFont('EN')
    },
    // 'wallpaperDatas.mainImage.optionsFont.TH.style': function(newValue) {
    //   this.optionsFont.TH = { ...this.wallpaperDatas.mainImage.optionsFont.TH }
    // },
    // 'wallpaperDatas.mainImage.optionsFont.EN.style': function(newValue) {
    //   this.optionsFont.EN = { ...this.wallpaperDatas.mainImage.optionsFont.EN }
    // },
    paginated(newValue) {
      const numSlice = this.limit - 20
      const listForLoad = [...newValue].slice(numSlice <= 0 ? 0 : numSlice)

      if (listForLoad.length <= 0) return
      this.loadGoogleFontSrc(listForLoad)
    },
    filtered() {
      // console.log(this.limit)
      if (this.limit > 20) {
        this.limit = 20
      }
    },
  },
  async mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)

    const imageList = this.wallpaperDatas.allImage

    if (imageList && imageList.length > 0) {
      const { fontColor } = imageList[0].options

      this.fontColor = `${fontColor}`
    }
  },
  methods: {
    async generateImagePreview() {
      this.loading = true
      // console.log('start')
      const mainImage = this.wallpaperDatas.mainImage

      const positionText = mainImage.positionList.filter(item => item.key === 'text')[0]
      const positionZodiac = mainImage.positionList.filter(item => item.key === 'zodiac')[0]

      // console.log('positionText', positionText)
      // test test ทดสอบ

      const form = new FormData()
      form.append('topic_data_type_id', {})
      form.append('id', mainImage?.changeId || mainImage?.id || '') // id อยู่ตรงนี้ครับ
      // form.append('change_topic_image_id', mainImage?.changeId || '')
      form.append('name', mainImage.title)
      form.append('details', mainImage.details)
      form.append('path', '')
      form.append('font_size', '')
      form.append('font_color', this.fontColor || '#ffffff')
      form.append('text_pointer_x', positionText.positionX)
      form.append('text_pointer_y', positionText.positionY)
      form.append('calc_text_width', Number(positionText.offsetBoxWidth).toFixed(2))
      form.append('calc_text_height', Number(positionText.offsetBoxHeight).toFixed(2))
      form.append('image_pointer_x', positionZodiac?.positionX ?? 0)
      form.append('image_pointer_y', positionZodiac?.positionY ?? 0)

      form.append('box_zodiac_width', Number(positionZodiac?.offsetBoxWidth ?? 0).toFixed(2)) // ต้องเป็น positionZodiac?.boxWidthCalculate
      form.append('box_zodiac_height', Number(positionZodiac?.offsetBoxHeight ?? 0).toFixed(2)) // ต้องเป็น positionZodiac?.boxWidthCalculate

      form.append('file', mainImage.file)
      form.append('text', this.text)
      form.append('allData', mainImage)
      form.append('topic_image_data_zodiac', mainImage?.zodiac?.id ?? '')

      // แนปฟ้อน
      form.append('path_font_TH', this.optionsFont.TH.style?.path || '')
      form.append('path_font_EN', this.optionsFont.EN.style?.path || '')

      //   Object.keys(this.wallpaperDatas.mainImage).forEach((field) => {
      //     console.log("field", field);
      //   });
      const data = await this.api.post('/api/partner/create-dummy-image', form, false)
      if (data) {
        // console.log(data)
        this.urlPreview = data.preview
      } else {
        this.urlPreview = null
      }

      this.loading = false
    },
    async fetchGoogleFont(key) {
      if (!key) return

      this.optionsFont[key].style = null
      // this.optionsFont[key].options = null
      const fontName = this.optionsFont[key].name.family

      if (!fontName) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `โปรดระบุชื่อฟ้อน ${key}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.pageLoading = true

      const res = await this.api.post(
        'api/customers/google-font',
        {
          fontname: fontName,
        },
        false,
      )
      // console.log(`[RES]: fetchGoogleFont key = ${key}`, res)

      if (typeof res === 'string') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `ไม่พบฟ้อน ${key} ที่ชื่อ ${fontName}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } else {
        this.optionsFont[key].options = res
        this.optionsFont[key].style = res.length > 0 ? { ...res[0] } : null
      }
      this.pageLoading = false
    },
    getOptionLabelStyleFont(option) {
      // console.log('option', option)
      const { font } = option
      return `${font}`.split('.')[0]
    },
    loadGoogleFontSrc(allFont) {
      // console.log('load', [...allFont].length)
      // <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
      for (let i = 0; i < allFont.length; i += 1) {
        const { family } = allFont[i]
        const fontFormat = `${family}`.split(' ').join('+')
        const cssId = `google-font-${fontFormat}-all`
        const existingFontLoad = document.getElementById(cssId)
        if (existingFontLoad) {
          return
        }

        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.id = cssId
        link.href = `https://fonts.googleapis.com/css2?family=${fontFormat}&display=swap`
        document.getElementsByTagName('head')[0].appendChild(link)
      }
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 20
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    updateOptionsFonts(obj) {
      this.optionsFontNameTH = [...obj.optionsFontNameTH]
      this.optionsFontNameEN = [...obj.optionsFontNameEN]
    },
    updateMainOptionsFont() {
      this.optionsFont = JSON.parse(JSON.stringify({ ...this.wallpaperDatas.mainImage.optionsFont }))
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-mt {
  margin: 0.5rem 0;
}
.container-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  .image-preview {
    // max-height: 200px;
    width: 100%;
    max-height: 500px;
  }
  .container-loading-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    .spinner-grow {
      width: 5rem;
      height: 5rem;
    }
  }
}
</style>
